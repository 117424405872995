const mapAirtableResponse = x => x.edges.map(y => y.node.data);

const transformFacilities = items => {
  if (!items) return [];
  return mapAirtableResponse(items).map(item => {
    return {
      icon: item.Icon,
      cardIcon: item.Card_Icon,
      name: item.Name,
      websiteFilterItem: item.Website_Filter_Item,
    };
  });
};
module.exports = transformFacilities;
