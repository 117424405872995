const mapAirtableResponse = x => x.edges.map(y => y.node.data);

const transformBuildings = items => {
  if (!items) return [];
  return mapAirtableResponse(items).map(item => {
    return {
      heroIcon: item.Hero_Icon,
      icon: item.Icon,
      name: item.Name,
      websiteFilterItem: item.Website_Filter_Item,
    };
  });
};
module.exports = transformBuildings;
