const mapAirtableResponse = x => x.edges.map(y => y.node);

const transformClubs = items => {
  if (!items) return [];
  return mapAirtableResponse(items).map(item => {
    return {
      row_idx: item.rowIndex,
      cardIcon: item.data.Card_Icon,
      icon: item.data.Icon,
      name: item.data.Name,
      websiteFilterItem: item.data.Website_Filter_Item,
      websiteTableRowColour: item.data.Website_Table_Row_Colour?.split('[')[1]?.replace(']', ''),
    };
  });
};
module.exports = transformClubs;
